import { connect } from 'react-redux'
import {
  selectTermsAcceptedAt,
  selectTermsOfService,
  selectTermsOfServiceErrorMessage,
  selectTermsOfServiceState
} from 'src/features/termsOfService/ducks/selectors'
import { logoutUserRoutine } from 'src/features/auth/duck/actions'
import {
  acceptTermsOfServiceRoutine,
  getTermsOfServiceRoutine
} from 'src/features/termsOfService/ducks/actions'

const mapStateToProps = state => ({
  termsOfService: selectTermsOfService(state),
  errorMessage: selectTermsOfServiceErrorMessage(state),
  state: selectTermsOfServiceState(state),
  termsAcceptedAt: selectTermsAcceptedAt(state)
})

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUserRoutine()),
  acceptTerms: () => dispatch(acceptTermsOfServiceRoutine()),
  getTermsOfService: () => dispatch(getTermsOfServiceRoutine())
})

export default connect(mapStateToProps, mapDispatchToProps)

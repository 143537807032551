import React from 'react'
import EUFinancingLogoUrl from 'src/assets/images/zestawienie_znakow_ue.jpg'
import styled from 'styled-components'
import { useSelect, useDispatch } from 'react-redux'
import { openEUFinancingDialog } from 'src/ducks/actions'

const EUFinancingLogo = styled.img`
  display: block;
  height: auto;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`

const EuFinancingLogoLarge = ({ className, onClick }) => {
  const dispatch = useDispatch()

  return (
    <a onClick={() => dispatch(openEUFinancingDialog())}>
      <EUFinancingLogo src={EUFinancingLogoUrl} />
    </a>
  )
}

export default EuFinancingLogoLarge

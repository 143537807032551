import React from 'react'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import {
  MainTitle,
  ArticleContentWrapper,
  Article
} from 'src/components/static/common'
import EuFinancingLogoLarge from 'src/components/header/components/EuFinancingLogoLarge'
import EUFinancingDialog from 'src/components/EUFinancingDialog'

export default () => (
  <ThemeProvider>
    <Layout>
      <MainTitle>Dlaczego warto u nas pracować ?</MainTitle>
      <ArticleContentWrapper>
        <Article>
          {/* <p>
            Jesteśmy firmą z kapitałem polskim z ugruntowaną pozycją rynkową.
            Stworzona przez nas internetowa giełda ładunków umożliwia klientom
            bezpieczny, profesjonalny dostęp do ładunków kontenerowych na całym
            świecie. Z uwagi na prężny rozwój firmy poszukujemy osobę na
            stanowisko:
          </p>
          <h2>Specjalista ds. sprzedaży i obsługi klienta</h2>
          <p>Miejsce pracy: Gdynia</p>
          <p>Nr Ref. 1/RR/2022</p>
          <br />
          <h4>ZAKRES OBOWIĄZKÓW:</h4> */}
          <ul>
            <li>Atmosfera jest super</li>
            <li>Warunki pracy</li>
            <li>Możliwość rozwoju</li>
            <li>Monitorowanie rynku konkurencji</li>
            <li>Stabilizacja</li>
            {/* <li>dbanie o prawidłowy wizerunek firmy</li> */}
          </ul>
          <br />
          <br />
          <p>
            Dobry pracownik to najcenniejszy kapitał, jaki firma posiada.
            Dlatego dbamy o nasz Team, ponieważ dzięki niemu nadal się
            rozwijamy, poszerzamy grono naszych klientów i odnosimy kolejne
            sukcesy.
          </p>
          <p>
            Chcesz brać udział w innowacyjnym projekcie i mieć realny wpływ na
            rozwój e-containers.eu? Przedstaw nam swoje mocne strony i
            dotychczasowe doświadczenie. Zapraszamy do bezpośredniego kontaktu:{' '}
            <a href='mailto:praca@e-containers.eu'>praca@e-containers.eu</a>
          </p>
          {/* <h4>WYMAGANIA:</h4>
          <ul>
            <li>znajomości języka angielskiego w stopniu komunikatywnym</li>
            <li>
              umiejętności nawiązywania kontaktów biznesowych i prowadzenia
              negocjacji
            </li>
            <li>samodzielności i bardzo dobrej organizacji pracy własnej</li>
            <li>orientacji na realizację określonych celów</li>
            <li>prawo jazdy kat. B</li>
            <li>mile widziana znajomość zagadnień z branży TSL</li>
          </ul>
          <br />
          <br />
          <h4>OFERUJEMY:</h4>
          <ul>
            <li>elastyczne warunki współpracy</li>
            <li>możliwość uczestnictwa w rozwoju unikatowego projektu firmy</li>
            <li>samodzielne i odpowiedzialne stanowisko pracy</li>
            <li>atrakcyjne wynagrodzenie (podstawa + prowizje)</li>
            <li>niezbędne narzędzia pracy (laptop, telefon)</li>
            <li>
              pracę w przyjaznej i miłej atmosferze w zespole doświadczonych
              specjalistów
            </li>
          </ul> */}
        </Article>
      </ArticleContentWrapper>
      <EUFinancingDialog />
    </Layout>
    <EuFinancingLogoLarge />
  </ThemeProvider>
)

import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import ReactMarkdown from 'react-markdown'
import Dialog from '@material-ui/core/Dialog'
import { breakpoint } from 'src/theme/grid'
import { CancelButton, SaveOfferButton } from 'src/components/atoms/Buttons'
import connect from 'src/features/termsOfService/ducks/connectors'
import { API_STATES, DATE_FORMAT } from 'src/ducks/consts'
import moment from 'moment'
import Alert from 'src/components/Alert'
import XIcon from 'src/assets/icons/close-x.svg'
import { EUFinancingLogo } from 'src/components/static/home'
import EUFinancingLogoUrl from 'src/assets/images/zestawienie_znakow_ue.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { closeEUFinancingDialog } from 'src/ducks/actions'

const EUFinancingDialog = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(state => state.euFinancingDialog.open)

  return (
    <Dialog maxWidth='lg' disableKeyboard onClose={() => {}} open={isOpen}>
      <Container>
        <DialogContent>
          <ModalCloseIcon onClick={() => dispatch(closeEUFinancingDialog())} />
          <EUFinancingLogo src={EUFinancingLogoUrl} />
        </DialogContent>
        <DialogTitle>
          <DialogTitleText>
            RR MEDIA Spółka z ograniczoną odpowiedzialności realizuje projekt
            dofinansowany z Funduszy Europejskich.
          </DialogTitleText>
        </DialogTitle>
        <DialogContent>
          <b>Tytuł projektu:</b> „Budowa i wdrożenie systemu informatycznego
          optymalizującego proces transportowy ładunków kontenerowych”.
        </DialogContent>
        <DialogContent>
          <b>Celem projektu</b> jest wdrożenie informatycznego systemu
          optymalizacji procesu transportowego ładunków kontenerowych. System
          informatyczny będzie składać się z giełdy przestrzeni i ładunków
          kontenerowych, poszerzonej o nowe funkcjonalności (licytacje ofert,
          monitoring przesyłek, automatycznie generowana dokumentacja
          dostawy/załadunku, automatycznie generowana dokumentacja sprzedażowa,
          rejestracja czasu pracy kierowcy, dane w chmurze). W rezultacie
          wprowadzone zostaną w firmie rozwiązania optymalizacji procesu
          transportowego ładunków kontenerowych, co przyczyni się do
          usprawnienia funkcjonowania firm transportowych i spedycyjnych.
        </DialogContent>
        <DialogContent>
          <b>Wartość całkowita projektu:</b> 1 070 100,00 PLN
        </DialogContent>
        <DialogContent>
          <b>Wkład Funduszy Europejskich:</b> 288 535,76 PLN
        </DialogContent>

        {/* <TermsPreview>
          <ReactMarkdown source={termsOfService.content} />
        </TermsPreview> */}
        {/* {errorMessage && <Alert type='alert' message={errorMessage} />} */}
        {/* <DialogActions>
          <CancelButton
            active
            onClick={() => {
            //   logoutUser()
            }}
          >
            <FormattedMessage id='termsOfService.reject' />
          </CancelButton> */}
        {/* <SaveOfferButton
            active={state !== API_STATES.IN_PROGRESS}
            onClick={() => {
              acceptTerms()
            }}
          >
            <FormattedMessage id='termsOfService.accept' />
          </SaveOfferButton> */}
        {/* </DialogActions> */}
      </Container>
    </Dialog>
  )
}

export default EUFinancingDialog

export const Container = styled.div`
  padding: 1.5rem 1rem 0rem 1rem;
  & > div {
    margin: 0.7rem 0;
  }

  ${breakpoint.m`
        padding: 1.5rem 2rem 0rem 2rem;
    `}
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const DialogTitleText = styled.h3`
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.78;
  color: ${({ theme }) => theme.colors.black.hex()};
`
export const DialogContent = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.71;

  & ul {
    list-style-type: disc;
    padding-left: 2rem;
  }
  & ol {
    padding-left: 2rem;
  }
  & a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
    &:hover {
      color: ${({ theme }) => theme.colors.easternBlue.darken().hex()};
    }
  }
`

export const DialogSubtitle = styled.h5`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
`

export const TermsPreview = styled(DialogContent)`
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 300px;
  background-color: #f8f8f9;
  border-radius: 4px;
  padding: 0.5rem;
`

const DialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ withReason }) =>
    withReason &&
    css`
      && {
        margin: 0 0 0.7rem 0;
      }
    `}
`

const ModalCloseIcon = styled(XIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black.hex()};
  position: absolute;
  top: 8px;
  right: 9px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.ebonyClay.alpha(0.1).hex()};
  }
`
